import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import dynamic from "next/dynamic";

import PointOfSaleChoiceWrapper from "@components/layouts/pointofsalechoiceWrapper";
import MainBanner from "@components/pointofsalechoice/mainBanner";

const OffersList = dynamic(() => import("@components/shared/offersList"));
const MiddleSection = dynamic(
    () => import("@components/pointofsalechoice/middleSection"),
);
const WhyUs = dynamic(() => import("@components/pointofsalechoice/whyUs"));

export default function AtmMachinePricesHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <PointOfSaleChoiceWrapper>
            <MainBanner />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhyUs />
            <MiddleSection />
        </PointOfSaleChoiceWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
